/*
 * @Descripttion:
 * @Author: wuxinxin
 * @Date: 2021-12-07 10:39:01
 * @LastEditors: wuxinxin
 * @LastEditTime: 2021-12-07 18:39:37
 */
import http from '@/utils/http';

//  管理员分页列表查询
export function pageByManage(formdata: any): any {
  return http.get(`/passport-resources/1/passport/pageByManage`, formdata);
}
//  管理员新增
export function addPassport(formdata: any): any {
  return http.post(`/passport-resources/1/passport/appManage`, formdata);
}
//  管理员删除
export function deletePassport(formdata: any): any {
  return http.delete(`/passport-resources/1/passport`, formdata);
}
//  管理员启用
export function enable(formdata: any): any {
  return http.put(`/passport-resources/1/passport/enable`, formdata);
}
//  管理员停用
export function disable(formdata: any): any {
  return http.put(`/passport-resources/1/passport/disable`, formdata);
}
