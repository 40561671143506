



























































































































































































import Vue from 'vue';
import * as Api from '@/api/manager';
import moment from 'moment';
const columns = [
  {
    title: '姓名',
    dataIndex: 'realName',
    key: 'realName',
    align: 'center',
  },
  {
    title: '登陆账号',
    dataIndex: 'userCode',
    key: 'userCode',
    align: 'left',
  },
  {
    title: '手机号码',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'center',
    scopedSlots: { customRender: 'mobile' },
  },
  {
    title: '创建人',
    dataIndex: 'extend1',
    key: 'extend1',
    align: 'left',
  },
  {
    title: '创建时间',
    dataIndex: 'recDate',
    key: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '备注',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '状态',
    dataIndex: 'recStatus',
    key: 'recStatus',
    align: 'center',
    scopedSlots: { customRender: 'recStatus' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'projectList',
  props: {},
  data() {
    return {
      tableLoading: false,
      formState: {
        recStatus: 'A',
        realName: '',
        userCode: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      dataSource,
      columns,
      visible: false,
      stopVisible: false,
      stopData: '',
      restoreVisible: false,
      restoreData: '',
      deleteVisible: false,
      deleteData: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      selected: [], //选中的数据
      selectedRowKeys: [],
      applyTime: ['', ''],
      loading: false,
      form: {
        userCode: '',
        realName: '',
        mobile: '',
        description: '',
      },
      rules: {
        userCode: [
          {
            required: true,
            message: '请输入登录账号',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 20,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{3,20}$/,
            message: '请输入长度不允许低于3位并不超过20位的字母和数字组合',
            trigger: 'blur',
          },
        ],
        realName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 10,
            message: '长度不允许低于2位并不超过10位',
            trigger: 'blur',
          },
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: '请输入正确手机号',
            trigger: 'blur',
          },
        ],
        description: [
          {
            min: 0,
            max: 85,
            message: '账号备注不超过85个字符',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.getManagerList();
  },
  methods: {
    // onSelectChange(selectedRowKeys, selectedRows) {
    //   console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
    //   this.selectedRowKeys = selectedRowKeys;
    //   this.selected = selectedRows;
    // },
    moment,
    /**
     * @description: 查询时间处理
     * @param {*}
     * @return {*}
     */
    getStartTime(time) {
      if (time) {
        return moment(time).startOf('day').valueOf();
      } else {
        return '';
      }
    },
    getEndTime(time) {
      if (time) {
        return moment(time).endOf('day').valueOf();
      } else {
        return '';
      }
    },
    /**
     * @description: 查询按钮
     * @param {*}
     * @return {*}
     */
    getManagerList() {
      this.tableLoading = true;
      const startTime = this.getStartTime(this.applyTime[0]);
      const endTime = this.getEndTime(this.applyTime[1]);
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        queryStatus: this.formState.recStatus,
        extend1: this.formState.userCode,
        startTime,
        endTime,
      };
      Api.pageByManage(param)
        .then((res) => {
          if (res.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
            this.selectedRowKeys = [];
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getManagerList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getManagerList();
    },
    /**
     * @description: 账号停用按钮
     * @param {*}
     * @return {*}
     */
    stop(record) {
      this.stopVisible = true;
      this.stopData = record.openId;
    },
    /**
     * @description: 账号停用弹框关闭按钮
     * @param {*}
     * @return {*}
     */
    stopTip() {
      // const formData: any = [];
      // this.selected.map((obj: any) => {
      //   formData.push({ openId: obj.openId, recStatus: 'I' });
      // }); //筛选账号id
      const formData = [{ openId: this.stopData, recStatus: 'I' }];
      Api.disable(formData).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('账号停用成功！');
          this.stopVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error('账号停用失败！');
        }
      });
    },
    /**
     * @description: 账号恢复弹框关闭按钮
     * @param {*}
     * @return {*}
     */
    restore(record) {
      this.restoreVisible = true;
      this.restoreData = record.openId;
    },
    /**
     * @description: 账号恢复按钮
     * @param {*}
     * @return {*}
     */
    restoreTip() {
      // const formData: any[] = [];
      // this.selected.map((obj: any) => {
      //   formData.push({ openId: obj.openId, recStatus: 'A' });
      // }); //筛选账号id
      const formData = [{ openId: this.restoreData, recStatus: 'A' }];
      Api.enable(formData).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('账号恢复成功！');
          this.restoreVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error('账号恢复失败！');
        }
      });
    },
    /**
     * @description: 删除弹框打开按钮
     * @param {*}
     * @return {*}
     */
    openDelete(record) {
      this.deleteVisible = true;
      this.deleteData = record.openId;
    },
    /**
     * @description: 删除弹框确认按钮
     * @param {*}
     * @return {*}
     */
    Delete() {
      const params = { openId: this.deleteData, recStatus: 'D' };
      Api.deletePassport(params).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('账号删除成功！');
          this.deleteVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error('账号删除失败！');
        }
      });
    },
    /**
     * @description: 新增管理员弹框打开按钮
     * @param {*}
     * @return {*}
     */ showModal() {
      this.form.realName = '';
      this.form.description = '';
      this.form.userCode = '';
      this.form.mobile = '';
      this.visible = true;
    },
    /**
     * @description: 新增管理员弹框确认提交按钮
     * @param {*}
     * @return {*}
     */
    handleOk() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.loading = true;
          Api.addPassport(this.form)
            .then((res) => {
              if (res.status === 200) {
                this.$message.success('账号新增成功！');
                this.loading = false;
                this.visible = false;
                this.handleSearchSubmit();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
      this.visible = false;
    },
    /**
     * @description: 新增管理员弹框取消按钮
     * @param {*}
     * @return {*}
     */
    handleCancel() {
      this.visible = false;
    },
  },
});
